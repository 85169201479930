var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.group
      ? _c("div", [
          _c("h1", { staticClass: "title" }, [_vm._v(_vm._s(_vm.group.label))]),
          _vm._v(" "),
          _c("p", { staticClass: "subtitle is-5" }, [
            _vm._v("ServiceID: " + _vm._s(_vm.serviceId)),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.posts.length > 0
      ? _c(
          "ul",
          { staticClass: "mt-6" },
          _vm._l(_vm.posts, function (post) {
            return _c(
              "li",
              { staticClass: "block" },
              [_c("post-list-item", { attrs: { post: post } })],
              1
            )
          }),
          0
        )
      : _c("div", [_c("p", [_vm._v(_vm._s(_vm.$t('msg["Data is empty"]')))])]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }