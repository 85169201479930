var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.$t("page.Services"))),
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "router-link",
          { staticClass: "button", attrs: { to: `/admin/services/create` } },
          [_vm._v(_vm._s(_vm.$t("common.createNew")))]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mt-5" }, [_c("service-list")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }