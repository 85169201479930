var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.users.length > 0
      ? _c("div", { staticClass: "table-container" }, [
          _c("table", { staticClass: "table" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.users, function (user) {
                return _c("tr", [
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: `/admin/users/${user.username}` } },
                        [_vm._v(_vm._s(user.username))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(user.email))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(_vm.$t(`term.userRoles.${user.role}`))),
                  ]),
                  _vm._v(" "),
                  _c("td", {
                    domProps: {
                      textContent: _vm._s(user.acceptServiceIds.join(", ")),
                    },
                  }),
                ])
              }),
              0
            ),
          ]),
        ])
      : !_vm.isLoading
      ? _c("div", [_c("p", [_vm._v(_vm._s(_vm.$t('msg["Data is empty"]')))])])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Username")]),
        _vm._v(" "),
        _c("th", [_vm._v("Email")]),
        _vm._v(" "),
        _c("th", [_vm._v("Role")]),
        _vm._v(" "),
        _c("th", [_vm._v("ServiceId")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }