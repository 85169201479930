var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "media" }, [
    _c("span", { staticClass: "media-left" }, [
      _c(
        "button",
        {
          staticClass: "button is-small",
          attrs: { disabled: _vm.selectDisabled },
          on: {
            click: function ($event) {
              return _vm.selectPost(_vm.post)
            },
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.$t("common.select")))]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "media-content" }, [
      _c("div", [_vm._v(_vm._s(_vm.post.title))]),
      _vm._v(" "),
      _c("div", { staticClass: "is-size-7" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("common.publishAt")))]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm._f("dateFormat")(_vm.post.publishAt)))]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-plus" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }