var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "upload-file-box" }, [
        _vm.isFileObject === true
          ? _c("div", { staticClass: "has-text-weight-semibold" }, [
              _vm._v(_vm._s(_vm.file.name)),
            ])
          : _c("div", {
              staticClass: "has-text-weight-semibold",
              domProps: {
                textContent: _vm._s(
                  _vm.file.originalName
                    ? _vm.file.originalName
                    : _vm.file.fileId
                ),
              },
            }),
        _vm._v(" "),
        _vm.file.size
          ? _c("p", { staticClass: "is-size-6" }, [
              _vm._v(_vm._s(_vm._f("formatBytes")(_vm.file.size))),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "button is-light is-small btn-delete",
            on: { click: _vm.deleteFile },
          },
          [_vm._m(0)]
        ),
      ]),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "has-text-danger" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : _vm.file.isUploaded
        ? _c("div", { staticClass: "has-text-success" }, [_vm._v("Uploaded")])
        : _vm._e(),
      _vm._v(" "),
      _vm.enableCaption && _vm.isFileObject === false
        ? _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: _vm.$t("common.dispLabel"),
                    "label-position": "inside",
                  },
                },
                [
                  _c("b-input", {
                    on: { blur: _vm.inputCaption },
                    model: {
                      value: _vm.caption,
                      callback: function ($$v) {
                        _vm.caption = $$v
                      },
                      expression: "caption",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isFileObject === false
        ? _c("div", { staticClass: "mt-3" }, [
            _c("div", { staticClass: "mt-2" }, [
              _c(
                "button",
                {
                  staticClass: "button",
                  on: {
                    click: function ($event) {
                      return _vm.copyUrl()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "icon" }, [
                    _vm.actionButtonType === "copy"
                      ? _c("i", { staticClass: "fas fa-copy" })
                      : _c("i", { staticClass: "fas fa-plus" }),
                  ]),
                  _vm._v(" "),
                  _vm.actionButtonType === "copy"
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("common.copyFor", { target: "URL" }))
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("b-loading", {
        attrs: { "is-full-page": false },
        model: {
          value: _vm.isUploading,
          callback: function ($$v) {
            _vm.isUploading = $$v
          },
          expression: "isUploading",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-times-circle" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }