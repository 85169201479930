var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isAdminPath ? _c("eb-admin-navbar") : _c("eb-navbar"),
      _vm._v(" "),
      _vm.isAdminPostPages ? _c("eb-admin-tab-menu-post") : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "columns is-desktop" }, [
          _c(
            "main",
            { staticClass: "section column" },
            [
              _c("b-loading", {
                attrs: {
                  active: _vm.isLoading,
                  "is-full-page": true,
                  canCancel: true,
                },
              }),
              _vm._v(" "),
              _c("router-view"),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isAdminPath
            ? _c(
                "div",
                { staticClass: "column is-3-desktop is-2-widescreen" },
                [_c("eb-side-nav-menu")],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }