var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.posts.length > 0
      ? _c(
          "ul",
          _vm._l(_vm.posts, function (post) {
            return _c(
              "li",
              { staticClass: "block" },
              [
                ["simple", "simpleSelect"].includes(_vm.listType)
                  ? _c("post-list-item-for-select", {
                      staticClass: "mt-3",
                      attrs: {
                        post: post,
                        "select-disabled": _vm.selectedIds.includes(
                          post.postId
                        ),
                      },
                      on: { select: _vm.selectPost },
                    })
                  : _vm.listType === "normal"
                  ? _c("post-list-item", { attrs: { post: post } })
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        )
      : _vm.isLoading === false
      ? _c("div", [_c("p", [_vm._v(_vm._s(_vm.$t('msg["Data is empty"]')))])])
      : _vm._e(),
    _vm._v(" "),
    _vm.hasNext
      ? _c("nav", { staticClass: "u-mt2r" }, [
          _c(
            "a",
            {
              staticClass: "u-clickable",
              on: {
                click: function ($event) {
                  return _vm.fetchPosts()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("common.more")))]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }