<template>
  <div>
    <h1 class="title">{{ $t('page.top') }}</h1>
  </div>
</template>
<script>

export default{
  name: 'Top',

  components: {
  },

  data(){
    return {
    }
  },

  computed: {
  },

  created() {
  }
}
</script>

