var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    [
      _c(
        "router-link",
        {
          class: { "is-active": _vm.activeLabel == _vm.tag.label },
          attrs: { to: `/posts/${_vm.serviceId}/tags/${_vm.tag.label}` },
        },
        [_vm._v(_vm._s(_vm.tag.label))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }