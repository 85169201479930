var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "block" },
      [
        _c(
          "router-link",
          { attrs: { to: `/admin/posts/${_vm.serviceId}/groups` } },
          [
            _c("i", { staticClass: "fas fa-chevron-left" }),
            _vm._v(" "),
            _c("span", [
              _vm._v(_vm._s(_vm.$t("page.AdminPostGroupManagement"))),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.postGroup
      ? _c("div", [
          _c("h1", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("term.postGroup"))),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "subtitle is-5" }, [
            _vm._v(_vm._s(_vm.postGroupLabel)),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.hasEditorRole
      ? _c(
          "div",
          { staticClass: "is-pulled-right" },
          [
            _c("eb-dropdown", { attrs: { position: "is-bottom-left" } }, [
              _c(
                "span",
                {
                  staticClass: "icon",
                  attrs: { slot: "label" },
                  slot: "label",
                },
                [_c("i", { staticClass: "fas fa-edit" })]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dropdown-content" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item",
                      attrs: {
                        to: `/admin/posts/${_vm.serviceId}/groups/${_vm.slug}/edit`,
                      },
                    },
                    [
                      _c("span", { staticClass: "icon" }, [
                        _c("i", { staticClass: "fas fa-pen" }),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item is-clickable",
                      on: {
                        click: function ($event) {
                          return _vm.confirmDelete()
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "icon" }, [
                        _c("i", { staticClass: "fas fa-trash" }),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("common.delete")))]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt-6" },
      [
        _c("span", [
          _vm.hasEditorRole
            ? _c(
                "button",
                {
                  staticClass: "button",
                  on: {
                    click: function ($event) {
                      _vm.isPostModalActive = true
                    },
                  },
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("common.addFor", {
                          target: _vm.$t("common.post"),
                        })
                      )
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("b-modal", {
          attrs: {
            "destroy-on-hide": false,
            "aria-role": "dialog",
            "close-button-aria-label": "Close",
            "aria-modal": "",
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (props) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "modal-card",
                      staticStyle: { width: "auto" },
                    },
                    [
                      _c("header", { staticClass: "modal-card-head" }, [
                        _c("p", { staticClass: "modal-card-title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t("common.selectFor", {
                                  target: _vm.$t("common.post"),
                                })
                              ) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("button", {
                          staticClass: "delete",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.isPostModalActive = false
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "section",
                        { staticClass: "modal-card-body" },
                        [
                          _c("post-list", {
                            attrs: {
                              "list-type": "simpleSelect",
                              "selected-ids": _vm.postIds,
                            },
                            on: { select: _vm.addGroupItem },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("footer", { staticClass: "modal-card-foot" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.isPostModalActive = false
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("common.close")))]
                        ),
                      ]),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.isPostModalActive,
            callback: function ($$v) {
              _vm.isPostModalActive = $$v
            },
            expression: "isPostModalActive",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.groupItems.length > 0
      ? _c(
          "div",
          { staticClass: "mt-5" },
          [
            _c(
              "draggable",
              {
                attrs: {
                  group: `postGroup-${_vm.slug}`,
                  options: { handle: ".handle" },
                },
                on: {
                  start: function ($event) {
                    _vm.drag = true
                  },
                  end: function ($event) {
                    _vm.drag = false
                  },
                  update: _vm.updatePostGroup,
                },
                model: {
                  value: _vm.groupItems,
                  callback: function ($$v) {
                    _vm.groupItems = $$v
                  },
                  expression: "groupItems",
                },
              },
              _vm._l(_vm.groupItems, function (post) {
                return _c(
                  "div",
                  {
                    key: post.postId,
                    staticClass: "columns is-mobile is-vcentered is-1",
                  },
                  [
                    _c("div", { staticClass: "column is-1 is-size-6" }, [
                      _vm.hasEditorRole
                        ? _c(
                            "button",
                            { staticClass: "button is-small handle" },
                            [
                              _c("span", { staticClass: "icon is-small" }, [
                                _c("i", { staticClass: "fas fa-sort" }),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "column is-size-6" }, [
                      _c("div", [_vm._v(_vm._s(post.title))]),
                      _vm._v(" "),
                      _c("div", { staticClass: "is-size-7" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("common.publishAt"))),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("dateFormat")(post.publishAt))),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "column is-1 is-size-6" }, [
                      _vm.hasEditorRole
                        ? _c(
                            "button",
                            {
                              staticClass: "button is-small",
                              on: {
                                click: function ($event) {
                                  return _vm.deletePostGroupItem(post.postId)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "icon is-small" }, [
                                _c("i", { staticClass: "fas fa-trash" }),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                )
              }),
              0
            ),
          ],
          1
        )
      : _vm.isLoading === false
      ? _c("div", { staticClass: "mt-5" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t('msg["Data is empty"]')))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-plus" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }