var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", [_vm._v(_vm._s(_vm.comment.body))]),
      _vm._v(" "),
      _c("div", [
        _c(
          "time",
          {
            attrs: {
              itemprop: "datepublished",
              datetime: _vm._f("dateFormat")(_vm.comment.createdAt, ""),
            },
          },
          [_vm._v(_vm._s(_vm._f("dateFormat")(_vm.comment.createdAt)))]
        ),
      ]),
      _vm._v(" "),
      _c("vote-unit", {
        attrs: {
          "vote-types": _vm.voteTypes,
          "posted-votes": _vm.postedVotes,
          "content-id": _vm.comment.commentId,
        },
        on: { "post-vote": _vm.postVote },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }