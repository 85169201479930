var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.category
        ? _c("div", [
            _c("h1", { staticClass: "title" }, [
              _vm._v(
                _vm._s(_vm.$t("common.postsOf", { label: _vm.category.label }))
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "subtitle is-5" }, [
              _vm._v("ServiceID: " + _vm._s(_vm.serviceId)),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("post-list", { staticClass: "mt-6" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }