var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.fileType === "image"
        ? _c(
            "ul",
            { staticClass: "columns is-multiline" },
            _vm._l(_vm.files, function (file, index) {
              return _c(
                "li",
                {
                  key: file.fileId,
                  staticClass:
                    "column is-half-tablet is-one-third-desktop is-one-quarter-widescreen",
                },
                [
                  _c("file-uploader-image", {
                    attrs: {
                      file: file,
                      "enable-caption": true,
                      "action-button-type": _vm.imageActionButtonType,
                      "uploader-options": _vm.uploaderOptions,
                    },
                    on: {
                      "uploaded-file": _vm.setUploadedFile,
                      "delete-file": _vm.deleteFile,
                      "input-caption": _vm.inputCaption,
                      "insert-image": _vm.insertImage,
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm.fileType === "file"
        ? _c(
            "ul",
            { staticClass: "columns is-multiline" },
            _vm._l(_vm.files, function (file, index) {
              return _c(
                "li",
                {
                  key: file.fileId,
                  staticClass: "column is-half-desktop is-one-third-widescreen",
                },
                [
                  _c("file-uploader-file", {
                    attrs: {
                      file: file,
                      "enable-caption": true,
                      "action-button-type": _vm.fileActionButtonType,
                      "uploader-options": _vm.uploaderOptions,
                    },
                    on: {
                      "uploaded-file": _vm.setUploadedFile,
                      "delete-file": _vm.deleteFile,
                      "input-caption": _vm.inputCaption,
                      "copy-url": _vm.copyUrl,
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-upload",
        {
          staticClass: "file-label",
          attrs: {
            multiple: "",
            accept: _vm.getUploadConfig("mimeTypes", []).join(","),
          },
          on: { input: _vm.setFileId },
          model: {
            value: _vm.files,
            callback: function ($$v) {
              _vm.files = $$v
            },
            expression: "files",
          },
        },
        [
          _c(
            "span",
            { staticClass: "file-cta" },
            [
              _c("b-icon", {
                staticClass: "file-icon",
                attrs: { pack: "fas", icon: "upload" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "file-label" }, [
                _vm._v(_vm._s(_vm.buttonLabel)),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }