var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v(
          _vm._s(_vm.$t("common.editOf", { label: _vm.$t("common.post") }))
        ),
      ]),
      _vm._v(" "),
      _vm.post != null
        ? _c("admin-post-form", { attrs: { post: _vm.post } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }