var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c("h1", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("page.AdminPostGroupManagement"))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "subtitle is-5" }, [
          _vm._v("ServiceID: " + _vm._s(_vm.serviceId)),
        ]),
        _vm._v(" "),
        _vm.hasEditorRole
          ? _c(
              "router-link",
              {
                staticClass: "button",
                attrs: { to: `/admin/posts/${_vm.serviceId}/groups/create` },
              },
              [_vm._v(_vm._s(_vm.$t("common.createNew")))]
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _vm.groups.length > 0
      ? _c("div", { staticClass: "mt-6" }, [
          _c("table", { staticClass: "table is-fullwidth" }, [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "is-size-6" }, [
                  _vm._v(_vm._s(_vm.$t("form.Slug"))),
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "is-size-6" }, [
                  _vm._v(_vm._s(_vm.$t("common.dispLabel"))),
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "is-size-7" }, [
                  _vm._v(_vm._s(_vm.$t("common.edit"))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.groups, function (group) {
                return _c("admin-post-groups-table-row", {
                  key: group.slug,
                  attrs: { group: group },
                  on: { deleted: _vm.deleteGroup },
                })
              }),
              1
            ),
          ]),
        ])
      : _c("div", { staticClass: "mt-5" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t('msg["Data is empty"]')))]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }