var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("h1", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("common.posts"))),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "subtitle is-5" }, [
            _vm._v("ServiceID: " + _vm._s(_vm.serviceId)),
          ]),
          _vm._v(" "),
          _vm.outerSiteUrl
            ? _c("div", { staticClass: "is-pulled-right" }, [
                _c(
                  "a",
                  { attrs: { href: _vm.outerSiteUrl, target: "_blank" } },
                  [
                    _c("i", { staticClass: "fas fa-globe-asia" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("common.webSite")))]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasEditorRole
            ? _c(
                "router-link",
                {
                  staticClass: "button",
                  attrs: { to: `/admin/posts/${this.serviceId}/create` },
                },
                [_vm._v(_vm._s(_vm.$t("common.createNew")))]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("admin-post-list", { staticClass: "mt-6" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }