var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    _vm._l(_vm.voteTypes, function (voteType, index) {
      return _c("vote-unit-item", {
        key: voteType,
        class: index > 0 ? "ml-4" : "",
        attrs: {
          "content-id": _vm.contentId,
          "vote-type": voteType,
          "vote-count": _vm.getVoteCount(voteType),
        },
        on: { "post-vote": _vm.postVote },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }