var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _c(
      "a",
      {
        class: { "is-active": _vm.activeCategorySlug == _vm.category.slug },
        on: {
          click: function ($event) {
            _vm.isExpanded = !_vm.isExpanded
          },
        },
      },
      [
        _c(
          "span",
          {
            staticClass: "u-underline",
            on: {
              click: function ($event) {
                return _vm.linkToCate(_vm.category.slug)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.category.label) + "\n    ")]
        ),
        _vm._v(" "),
        _c("b-icon", {
          staticClass: "is-pulled-right",
          attrs: {
            pack: "fas",
            icon: _vm.isExpanded ? "caret-up" : "caret-down",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "ul",
      { class: { "is-active": _vm.isExpanded } },
      _vm._l(_vm.category.children, function (item) {
        return _c(
          "li",
          { key: item.id },
          [
            _c(
              "router-link",
              {
                class: { "is-active": _vm.activeCategorySlug == item.slug },
                attrs: {
                  to: `/posts/${_vm.serviceId}/categories/${item.slug}`,
                },
              },
              [_vm._v(_vm._s(item.label))]
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }