var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isEdit
        ? _c("b-field", { attrs: { label: "serviceId" } }, [
            _vm._v(_vm._s(_vm.service.serviceId)),
          ])
        : _c(
            "b-field",
            {
              attrs: {
                label: "serviceId",
                type: _vm.checkEmpty(_vm.errors.serviceIdInput)
                  ? ""
                  : "is-danger",
                message: _vm.checkEmpty(_vm.errors.serviceIdInput)
                  ? ""
                  : _vm.errors.serviceIdInput[0],
              },
            },
            [
              _c("b-input", {
                on: {
                  blur: function ($event) {
                    return _vm.validate("serviceIdInput")
                  },
                },
                model: {
                  value: _vm.serviceIdInput,
                  callback: function ($$v) {
                    _vm.serviceIdInput = $$v
                  },
                  expression: "serviceIdInput",
                },
              }),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "b-field",
        {
          attrs: {
            label: "label",
            type: _vm.checkEmpty(_vm.errors.label) ? "" : "is-danger",
            message: _vm.checkEmpty(_vm.errors.label)
              ? ""
              : _vm.errors.label[0],
          },
        },
        [
          _c("b-input", {
            on: {
              blur: function ($event) {
                return _vm.validate("label")
              },
            },
            model: {
              value: _vm.label,
              callback: function ($$v) {
                _vm.label = $$v
              },
              expression: "label",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        {
          staticClass: "mb-0",
          attrs: { label: _vm.$t("form.functionToApply") },
        },
        [
          _c(
            "b-checkbox",
            {
              attrs: { "native-value": "post" },
              model: {
                value: _vm.functions,
                callback: function ($$v) {
                  _vm.functions = $$v
                },
                expression: "functions",
              },
            },
            [_vm._v(_vm._s(_vm.$t("term.availableFunctions.post")))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.functions.includes("post")
        ? _c(
            "div",
            { staticClass: "pl-5 pt-2 pb-4" },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: _vm.$t("form.outerSiteUrl"),
                    type: _vm.checkEmpty(_vm.errors.outerSiteUrl)
                      ? ""
                      : "is-danger",
                    message: _vm.checkEmpty(_vm.errors.outerSiteUrl)
                      ? ""
                      : _vm.errors.outerSiteUrl[0],
                  },
                },
                [
                  _c("b-input", {
                    on: {
                      blur: function ($event) {
                        return _vm.validate("outerSiteUrl")
                      },
                    },
                    model: {
                      value: _vm.outerSiteUrl,
                      callback: function ($$v) {
                        _vm.outerSiteUrl = $$v
                      },
                      expression: "outerSiteUrl",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: _vm.$t("form.frontendPostDetailUrlPrefix"),
                    type: _vm.checkEmpty(_vm.errors.frontendPostDetailUrlPrefix)
                      ? ""
                      : "is-danger",
                    message: _vm.checkEmpty(
                      _vm.errors.frontendPostDetailUrlPrefix
                    )
                      ? ""
                      : _vm.errors.frontendPostDetailUrlPrefix[0],
                  },
                },
                [
                  _c("b-input", {
                    on: {
                      blur: function ($event) {
                        return _vm.validate("frontendPostDetailUrlPrefix")
                      },
                    },
                    model: {
                      value: _vm.frontendPostDetailUrlPrefix,
                      callback: function ($$v) {
                        _vm.frontendPostDetailUrlPrefix = $$v
                      },
                      expression: "frontendPostDetailUrlPrefix",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: _vm.$t("form.mediaUploadAcceptMimetypesFor", {
                      target: _vm.$t("common.images"),
                    }),
                    type: _vm.checkEmpty(
                      _vm.errors.mediaUploadAcceptMimetypesImage
                    )
                      ? ""
                      : "is-danger",
                    message: _vm.checkEmpty(
                      _vm.errors.mediaUploadAcceptMimetypesImage
                    )
                      ? ""
                      : _vm.errors.mediaUploadAcceptMimetypesImage[0],
                  },
                },
                [
                  _c("b-input", {
                    on: {
                      blur: function ($event) {
                        return _vm.validate("mediaUploadAcceptMimetypesImage")
                      },
                    },
                    model: {
                      value: _vm.mediaUploadAcceptMimetypesImage,
                      callback: function ($$v) {
                        _vm.mediaUploadAcceptMimetypesImage = $$v
                      },
                      expression: "mediaUploadAcceptMimetypesImage",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: _vm.$t("form.mediaUploadImageSizes"),
                    type: _vm.checkEmpty(_vm.errors.mediaUploadImageSizes)
                      ? ""
                      : "is-danger",
                    message: _vm.checkEmpty(_vm.errors.mediaUploadImageSizes)
                      ? ""
                      : _vm.errors.mediaUploadImageSizes[0],
                  },
                },
                [
                  _c("b-input", {
                    on: {
                      blur: function ($event) {
                        return _vm.validate("mediaUploadImageSizes")
                      },
                    },
                    model: {
                      value: _vm.mediaUploadImageSizes,
                      callback: function ($$v) {
                        _vm.mediaUploadImageSizes = $$v
                      },
                      expression: "mediaUploadImageSizes",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: _vm.$t("form.mediaUploadSizeLimitMBImage"),
                    type: _vm.checkEmpty(_vm.errors.mediaUploadSizeLimitMBImage)
                      ? ""
                      : "is-danger",
                    message: _vm.checkEmpty(
                      _vm.errors.mediaUploadSizeLimitMBImage
                    )
                      ? ""
                      : _vm.errors.mediaUploadSizeLimitMBImage[0],
                  },
                },
                [
                  _c("b-input", {
                    on: {
                      blur: function ($event) {
                        return _vm.validate("mediaUploadSizeLimitMBImage")
                      },
                    },
                    model: {
                      value: _vm.mediaUploadSizeLimitMBImage,
                      callback: function ($$v) {
                        _vm.mediaUploadSizeLimitMBImage = $$v
                      },
                      expression: "mediaUploadSizeLimitMBImage",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: _vm.$t("form.mediaUploadAcceptMimetypesFor", {
                      target: _vm.$t("common.files"),
                    }),
                    type: _vm.checkEmpty(
                      _vm.errors.mediaUploadAcceptMimetypesFile
                    )
                      ? ""
                      : "is-danger",
                    message: _vm.checkEmpty(
                      _vm.errors.mediaUploadAcceptMimetypesFile
                    )
                      ? ""
                      : _vm.errors.mediaUploadAcceptMimetypesFile[0],
                  },
                },
                [
                  _c("b-input", {
                    on: {
                      blur: function ($event) {
                        return _vm.validate("mediaUploadAcceptMimetypesFile")
                      },
                    },
                    model: {
                      value: _vm.mediaUploadAcceptMimetypesFile,
                      callback: function ($$v) {
                        _vm.mediaUploadAcceptMimetypesFile = $$v
                      },
                      expression: "mediaUploadAcceptMimetypesFile",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: _vm.$t("form.mediaUploadSizeLimitMBFile"),
                    type: _vm.checkEmpty(_vm.errors.mediaUploadSizeLimitMBFile)
                      ? ""
                      : "is-danger",
                    message: _vm.checkEmpty(
                      _vm.errors.mediaUploadSizeLimitMBFile
                    )
                      ? ""
                      : _vm.errors.mediaUploadSizeLimitMBFile[0],
                  },
                },
                [
                  _c("b-input", {
                    on: {
                      blur: function ($event) {
                        return _vm.validate("mediaUploadSizeLimitMBFile")
                      },
                    },
                    model: {
                      value: _vm.mediaUploadSizeLimitMBFile,
                      callback: function ($$v) {
                        _vm.mediaUploadSizeLimitMBFile = $$v
                      },
                      expression: "mediaUploadSizeLimitMBFile",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-field",
        { staticClass: "mb-0" },
        [
          _c(
            "b-checkbox",
            {
              attrs: { "native-value": "urlShortener" },
              model: {
                value: _vm.functions,
                callback: function ($$v) {
                  _vm.functions = $$v
                },
                expression: "functions",
              },
            },
            [_vm._v(_vm._s(_vm.$t("term.availableFunctions.urlShortener")))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.functions.includes("urlShortener")
        ? _c(
            "div",
            { staticClass: "pl-5 pt-2 pb-4" },
            [
              _c(
                "b-field",
                { attrs: { grouped: "" } },
                [
                  _c(
                    "b-field",
                    {
                      attrs: {
                        label: _vm.$t("form.jumpPageUrl"),
                        type: _vm.checkEmpty(_vm.errors.jumpPageUrl)
                          ? ""
                          : "is-danger",
                        message: _vm.checkEmpty(_vm.errors.jumpPageUrl)
                          ? ""
                          : _vm.errors.jumpPageUrl[0],
                        expanded: "",
                      },
                    },
                    [
                      _c("b-input", {
                        on: {
                          blur: function ($event) {
                            return _vm.validate("jumpPageUrl")
                          },
                        },
                        model: {
                          value: _vm.jumpPageUrl,
                          callback: function ($$v) {
                            _vm.jumpPageUrl = $$v
                          },
                          expression: "jumpPageUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    {
                      attrs: {
                        label: _vm.$t("form.jumpPageParamKey"),
                        type: _vm.checkEmpty(_vm.errors.jumpPageParamKey)
                          ? ""
                          : "is-danger",
                        message: _vm.checkEmpty(_vm.errors.jumpPageParamKey)
                          ? ""
                          : _vm.errors.jumpPageParamKey[0],
                      },
                    },
                    [
                      _c("b-input", {
                        on: {
                          blur: function ($event) {
                            return _vm.validate("jumpPageParamKey")
                          },
                        },
                        model: {
                          value: _vm.jumpPageParamKey,
                          callback: function ($$v) {
                            _vm.jumpPageParamKey = $$v
                          },
                          expression: "jumpPageParamKey",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: _vm.$t("form.analysisParamKeyDefault"),
                    type: _vm.checkEmpty(_vm.errors.analysisParamKeyDefault)
                      ? ""
                      : "is-danger",
                    message: _vm.checkEmpty(_vm.errors.analysisParamKeyDefault)
                      ? ""
                      : _vm.errors.analysisParamKeyDefault[0],
                  },
                },
                [
                  _c("b-input", {
                    on: {
                      blur: function ($event) {
                        return _vm.validate("analysisParamKeyDefault")
                      },
                    },
                    model: {
                      value: _vm.analysisParamKeyDefault,
                      callback: function ($$v) {
                        _vm.analysisParamKeyDefault = $$v
                      },
                      expression: "analysisParamKeyDefault",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.globalError
        ? _c("div", { staticClass: "block has-text-danger mt-5 mb-0" }, [
            _vm._v(_vm._s(_vm.globalError)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "field mt-5" }, [
        _c("div", { staticClass: "control" }, [
          _c("button", {
            staticClass: "button is-warning",
            attrs: { disabled: _vm.isLoading || _vm.hasErrors },
            domProps: { textContent: _vm._s(_vm.$t("common.edit")) },
            on: {
              click: function ($event) {
                return _vm.save(false)
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "control" }, [
          _c("button", {
            staticClass: "button is-light",
            attrs: { disabled: _vm.isLoading },
            domProps: { textContent: _vm._s(_vm.$t("common.cancel")) },
            on: { click: _vm.cancel },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }