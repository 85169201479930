var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-field",
        {
          staticClass: "mt-5",
          attrs: {
            label: _vm.$t("common.locationTo"),
            type: _vm.checkEmpty(_vm.errors.url) ? "" : "is-danger",
            placeholder: "https://example.com/",
            message: _vm.checkEmpty(_vm.errors.url) ? "" : _vm.errors.url[0],
          },
        },
        [
          _c("b-input", {
            on: {
              blur: function ($event) {
                return _vm.validate("url")
              },
            },
            model: {
              value: _vm.url,
              callback: function ($$v) {
                _vm.url = $$v
              },
              expression: "url",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isSetJumpPageConfigs
        ? _c(
            "b-field",
            {
              staticClass: "mt-5",
              attrs: {
                label: _vm.$t("form.isViaJumpPageLabel"),
                type: _vm.checkEmpty(_vm.errors.isViaJumpPage)
                  ? ""
                  : "is-danger",
                message: _vm.checkEmpty(_vm.errors.isViaJumpPage)
                  ? ""
                  : _vm.errors.isViaJumpPage[0],
              },
            },
            [
              _c(
                "b-checkbox",
                {
                  model: {
                    value: _vm.isViaJumpPage,
                    callback: function ($$v) {
                      _vm.isViaJumpPage = $$v
                    },
                    expression: "isViaJumpPage",
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("form.viaJumpPage")) + "\n    "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-field",
        {
          attrs: {
            label: _vm.$t("common.paramsFor", {
              target: _vm.$t("term.accessAnalysis"),
            }),
            message: _vm.checkEmpty(_vm.errors.paramKeyValue)
              ? ""
              : _vm.errors.paramKeyValue[0],
            type: _vm.checkEmpty(_vm.errors.paramKeyValue) ? "" : "is-danger",
            grouped: "",
          },
        },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: "paramKey",
                message: _vm.checkEmpty(_vm.errors.paramKey)
                  ? ""
                  : _vm.errors.paramKey[0],
                type: _vm.checkEmpty(_vm.errors.paramKey) ? "" : "is-danger",
              },
            },
            [
              _c("b-input", {
                on: {
                  blur: function ($event) {
                    return _vm.validate("paramKey")
                  },
                },
                model: {
                  value: _vm.paramKey,
                  callback: function ($$v) {
                    _vm.paramKey = $$v
                  },
                  expression: "paramKey",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              attrs: {
                label: "paramValue",
                message: _vm.checkEmpty(_vm.errors.paramValue)
                  ? ""
                  : _vm.errors.paramValue[0],
                type: _vm.checkEmpty(_vm.errors.ParamValue) ? "" : "is-danger",
              },
            },
            [
              _c("b-input", {
                attrs: { expanded: "" },
                on: {
                  blur: function ($event) {
                    return _vm.validate("paramValue")
                  },
                },
                model: {
                  value: _vm.paramValue,
                  callback: function ($$v) {
                    _vm.paramValue = $$v
                  },
                  expression: "paramValue",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.generatedUrl
        ? _c("div", { staticClass: "p-3 mt-4 has-background-light u-wrap" }, [
            _c("h5", { staticClass: "title is-6" }, [
              _vm._v(_vm._s(_vm.$t("term.generateUrl"))),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("a", { attrs: { href: _vm.generatedUrl, target: "_blank" } }, [
                _vm._v(_vm._s(_vm.generatedUrl)),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-field",
        {
          staticClass: "mt-5",
          attrs: {
            label: _vm.$t("common.name"),
            type: _vm.checkEmpty(_vm.errors.name) ? "" : "is-danger",
            message: _vm.checkEmpty(_vm.errors.name) ? "" : _vm.errors.name[0],
          },
        },
        [
          _c("b-input", {
            on: {
              blur: function ($event) {
                return _vm.validate("name")
              },
            },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        {
          attrs: {
            label: _vm.$t("common.description"),
            type: _vm.checkEmpty(_vm.errors.description) ? "" : "is-danger",
            message: _vm.checkEmpty(_vm.errors.description)
              ? ""
              : _vm.errors.description[0],
          },
        },
        [
          _c("b-input", {
            ref: "inputDescription",
            attrs: { type: "textarea" },
            on: {
              blur: function ($event) {
                return _vm.validate("description")
              },
            },
            model: {
              value: _vm.description,
              callback: function ($$v) {
                _vm.description = $$v
              },
              expression: "description",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.globalError
        ? _c("div", { staticClass: "block has-text-danger mt-5" }, [
            _vm._v(_vm._s(_vm.globalError)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "field mt-5" }, [
        _c("div", { staticClass: "control" }, [
          _c("button", {
            staticClass: "button is-warning",
            attrs: { disabled: _vm.isLoading || _vm.hasErrors },
            domProps: {
              textContent: _vm._s(
                _vm.isEdit ? _vm.$t("common.edit") : _vm.$t("common.create")
              ),
            },
            on: {
              click: function ($event) {
                return _vm.save(false)
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "control" }, [
          _c("button", {
            staticClass: "button is-light",
            attrs: { disabled: _vm.isLoading },
            domProps: { textContent: _vm._s(_vm.$t("common.cancel")) },
            on: { click: _vm.cancel },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }