var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v(
          _vm._s(_vm.$t("common.createOf", { label: _vm.$t("term.service") }))
        ),
      ]),
      _vm._v(" "),
      _c("admin-service-form"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }