var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("editor", {
        attrs: {
          value: _vm.value,
          "api-key": _vm.tinyMCEApiKey,
          init: _vm.editorOptions,
        },
        on: { input: _vm.changeValue },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }