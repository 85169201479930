var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.$t("page.AdminUserSettings"))),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mt-5" }, [
      _vm.user
        ? _c("table", { staticClass: "table" }, [
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v("Username")]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.user.username))]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v("Email")]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v(_vm._s(_vm.user.email))]),
                  _vm._v(" "),
                  _vm.user.emailVerified
                    ? _c("span", { staticClass: "has-text-success ml-2" }, [
                        _vm._v("Verified"),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v("Role")]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "b-select",
                      {
                        on: {
                          input: function ($event) {
                            return _vm.updateRole()
                          },
                        },
                        model: {
                          value: _vm.selectedRole,
                          callback: function ($$v) {
                            _vm.selectedRole = $$v
                          },
                          expression: "selectedRole",
                        },
                      },
                      _vm._l(_vm.roles, function (role) {
                        return _c(
                          "option",
                          { key: role, domProps: { value: role } },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t(`term.userRoles.${role}`)) +
                                "\n              "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v("Status")]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.user.status))]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("common.createdAt")))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(_vm._f("dateFormat")(_vm.user.createdAt))),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("common.updatedAt")))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(_vm._f("dateFormat")(_vm.user.updatedAt))),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mt-6" }, [
      _c("h2", { staticClass: "title is-4" }, [
        _vm._v(_vm._s(_vm.$t("term.acceptServices"))),
      ]),
      _vm._v(" "),
      _vm.services
        ? _c(
            "section",
            [
              _vm._l(_vm.services, function (service) {
                return _c(
                  "b-field",
                  { key: service.serviceId },
                  [
                    _c(
                      "b-checkbox",
                      {
                        attrs: { "native-value": service.serviceId },
                        model: {
                          value: _vm.selectedServiceIds,
                          callback: function ($$v) {
                            _vm.selectedServiceIds = $$v
                          },
                          expression: "selectedServiceIds",
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "has-text-weight-semibold" },
                          [_vm._v(_vm._s(service.serviceId))]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(service.label))]),
                      ]
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm.selectedServiceIdsError
                ? _c("p", { staticClass: "has-text-danger" }, [
                    _vm._v(_vm._s(_vm.selectedServiceIdsError)),
                  ])
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }