var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "navbar is-transparent" }, [
    _c("div", { staticClass: "navbar-brand" }, [
      _c(
        "a",
        { staticClass: "navbar-item", attrs: { href: "" } },
        [
          _c(
            "router-link",
            { staticClass: "navbar-item", attrs: { to: "/" } },
            [
              _c("img", {
                attrs: { src: _vm.logoUrl, width: "112", height: "28" },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "navbar-burger burger",
          class: { "is-active": _vm.isHeaderMenuOpen },
          on: {
            click: function ($event) {
              return _vm.toggleHeaderMenuOpen()
            },
          },
        },
        [_c("span"), _vm._v(" "), _c("span"), _vm._v(" "), _c("span")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "navbar-menu",
        class: { "is-active": _vm.isHeaderMenuOpen },
      },
      [
        _c(
          "div",
          { staticClass: "navbar-start" },
          [
            _c(
              "router-link",
              { staticClass: "navbar-item", attrs: { to: "/" } },
              [_vm._v(_vm._s(_vm.$t("common.top")))]
            ),
            _vm._v(" "),
            !_vm.isAuth
              ? _c(
                  "router-link",
                  { staticClass: "navbar-item", attrs: { to: "/signup" } },
                  [_vm._v(_vm._s(_vm.$t("common.signUp")))]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isAuth
              ? _c(
                  "router-link",
                  { staticClass: "navbar-item", attrs: { to: "/signin" } },
                  [_vm._v(_vm._s(_vm.$t("common.signIn")))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isAuth
              ? _c(
                  "router-link",
                  { staticClass: "navbar-item", attrs: { to: "/user" } },
                  [_vm._v(_vm._s(_vm.$t("page.userTop")))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isAuth
              ? _c(
                  "router-link",
                  { staticClass: "navbar-item", attrs: { to: "/settings" } },
                  [_vm._v(_vm._s(_vm.$t("page.Settings")))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isAuth
              ? _c(
                  "a",
                  {
                    staticClass: "navbar-item u-clickable",
                    on: { click: _vm.signOut },
                  },
                  [_vm._v(_vm._s(_vm.$t("common.signOut")))]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "router-link",
              { staticClass: "navbar-item", attrs: { to: "/about" } },
              [_vm._v(_vm._s(_vm.$t("page.about")))]
            ),
            _vm._v(" "),
            _vm.isAdminUser
              ? _c(
                  "router-link",
                  { staticClass: "navbar-item", attrs: { to: "/admin" } },
                  [_vm._v(_vm._s(_vm.$t("page.adminTop")))]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("router-link", {
              staticClass: "navbar-item",
              attrs: { to: "/" },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }