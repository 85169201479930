var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.services.length > 0
      ? _c("div", { staticClass: "table-container" }, [
          _c(
            "table",
            { staticClass: "table" },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._l(_vm.services, function (service) {
                return _c("tr", [
                  _c("td", { staticClass: "has-text-weight-semibold" }, [
                    _vm._v(_vm._s(service.serviceId)),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(service.label))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: `/admin/services/${service.serviceId}/edit`,
                          },
                        },
                        [
                          _c("span", { staticClass: "icon" }, [
                            _c("i", { staticClass: "fas fa-edit" }),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              }),
            ],
            2
          ),
        ])
      : !_vm.isLoading
      ? _c("div", [_c("p", [_vm._v(_vm._s(_vm.$t('msg["Data is empty"]')))])])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("serviceId")]),
      _vm._v(" "),
      _c("th", [_vm._v("label")]),
      _vm._v(" "),
      _c("th", [_vm._v("Edit")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }