var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "columns is-1 mt-0 mb-0" }, [
    _c("div", { staticClass: "column is-6" }, [
      _c(
        "div",
        [
          _c("b-input", {
            attrs: { placeholder: "URL" },
            on: {
              blur: function ($event) {
                return _vm.validateUrl()
              },
            },
            model: {
              value: _vm.url,
              callback: function ($$v) {
                _vm.url = $$v
              },
              expression: "url",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.checkEmpty(_vm.errors.url) === false
        ? _c("div", { staticClass: "has-text-danger" }, [
            _vm._v(_vm._s(_vm.errors.url)),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "column is-5" }, [
      _c(
        "div",
        [
          _c("b-input", {
            attrs: { placeholder: _vm.$t("common.dispLabel") },
            on: {
              blur: function ($event) {
                return _vm.validateLabel()
              },
            },
            model: {
              value: _vm.label,
              callback: function ($$v) {
                _vm.label = $$v
              },
              expression: "label",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.checkEmpty(_vm.errors.label) === false
        ? _c("div", { staticClass: "has-text-danger" }, [
            _vm._v(_vm._s(_vm.errors.label)),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "column is-1" }, [
      _c(
        "button",
        {
          staticClass: "button is-light is-small btn-delete",
          on: { click: _vm.deleteItem },
        },
        [_vm._m(0)]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-times-circle" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }