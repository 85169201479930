var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.post
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "block" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: _vm.isAdminPath
                      ? `/admin/posts/${_vm.serviceId}`
                      : `/posts/${_vm.serviceId}`,
                  },
                },
                [
                  _c("i", { staticClass: "fas fa-chevron-left" }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("common.posts")))]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("h1", { staticClass: "title" }, [_vm._v(_vm._s(_vm.post.title))]),
          _vm._v(" "),
          _vm.post.bodyHtml
            ? _c("post-body", { attrs: { body: _vm.post.bodyHtml } })
            : _vm._e(),
          _vm._v(" "),
          _vm.votes
            ? _c(
                "div",
                { staticClass: "mt-5" },
                [
                  _c("vote-unit", {
                    attrs: {
                      "vote-types": _vm.voteTypes,
                      "posted-votes": _vm.votes,
                      "content-id": _vm.post.postId,
                    },
                    on: { "post-vote": _vm.postVote },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "mt-5" }, [
            _c(
              "time",
              {
                attrs: {
                  itemprop: "datepublished",
                  datetime: _vm._f("dateFormat")(_vm.post.publishAt, ""),
                },
              },
              [_vm._v(_vm._s(_vm._f("dateFormat")(_vm.post.publishAt)))]
            ),
          ]),
          _vm._v(" "),
          _vm.post.category
            ? _c(
                "nav",
                {
                  staticClass: "breadcrumb",
                  attrs: { "aria-label": "breadcrumbs" },
                },
                [
                  _c(
                    "ul",
                    [
                      _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            { attrs: { to: `/posts/${_vm.serviceId}/` } },
                            [_vm._v(_vm._s(_vm.$t("common.posts")))]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.post.category.parents, function (parent) {
                        return _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: `/posts/${_vm.serviceId}/categories/${parent.slug}`,
                                },
                              },
                              [_vm._v(_vm._s(parent.label))]
                            ),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: `/posts/${_vm.serviceId}/categories/${_vm.post.category.slug}`,
                              },
                            },
                            [_vm._v(_vm._s(_vm.post.category.label))]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          "tags" in _vm.post && _vm.post.tags
            ? _c(
                "ul",
                _vm._l(_vm.post.tags, function (tag) {
                  return _c(
                    "li",
                    { staticClass: "is-inline-block mr-2" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "tag",
                          attrs: {
                            to: `/posts/${_vm.serviceId}/tags/${tag.label}`,
                          },
                        },
                        [_vm._v(_vm._s(tag.label))]
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isEnabledComment
            ? _c(
                "section",
                { staticClass: "mt-6" },
                [
                  _c("h3", { staticClass: "title is-4" }, [
                    _vm._v(_vm._s(_vm.$t("common.comments"))),
                  ]),
                  _vm._v(" "),
                  _vm.post
                    ? _c("comments", {
                        attrs: { "content-id": _vm.post.postId },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }