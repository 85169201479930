var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-field",
        {
          attrs: {
            label: _vm.$t("form.Slug"),
            type: _vm.checkEmpty(_vm.errors.slug) ? "" : "is-danger",
            message: _vm.checkEmpty(_vm.errors.slug) ? "" : _vm.errors.slug[0],
          },
        },
        [
          _c("b-input", {
            on: {
              blur: function ($event) {
                return _vm.validate("slug")
              },
            },
            model: {
              value: _vm.slug,
              callback: function ($$v) {
                _vm.slug = $$v
              },
              expression: "slug",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        {
          staticClass: "mt-6",
          attrs: {
            label: _vm.$t("common.category"),
            type: _vm.checkEmpty(_vm.errors.category) ? "" : "is-danger",
            message: _vm.checkEmpty(_vm.errors.category)
              ? ""
              : _vm.errors.category[0],
          },
        },
        [
          _c(
            "b-select",
            {
              attrs: {
                placeholder: _vm.$t("msg.SelectItem", {
                  name: _vm.$t("common.category"),
                }),
              },
              on: {
                input: function ($event) {
                  return _vm.validate("category")
                },
              },
              model: {
                value: _vm.category,
                callback: function ($$v) {
                  _vm.category = $$v
                },
                expression: "category",
              },
            },
            _vm._l(_vm.categories, function (pcate) {
              return _vm.categories
                ? _c(
                    "optgroup",
                    { key: pcate.slug, attrs: { label: pcate.label } },
                    _vm._l(pcate.children, function (cate) {
                      return _c(
                        "option",
                        { key: cate.slug, domProps: { value: cate.slug } },
                        [_vm._v(_vm._s(cate.label))]
                      )
                    }),
                    0
                  )
                : _vm._e()
            }),
            0
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        {
          staticClass: "mt-6",
          attrs: {
            label: _vm.$t("form.title"),
            type: _vm.checkEmpty(_vm.errors.title) ? "" : "is-danger",
            message: _vm.checkEmpty(_vm.errors.title)
              ? ""
              : _vm.errors.title[0],
          },
        },
        [
          _c("b-input", {
            on: {
              blur: function ($event) {
                return _vm.validate("title")
              },
            },
            model: {
              value: _vm.title,
              callback: function ($$v) {
                _vm.title = $$v
              },
              expression: "title",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        {
          staticClass: "mt-6",
          attrs: {
            label: _vm.$t("form.body"),
            message: _vm.checkEmpty(_vm.errors.editorModeOption)
              ? ""
              : _vm.errors.editorModeOption[0],
          },
        },
        [
          _c(
            "b-select",
            {
              model: {
                value: _vm.editorMode,
                callback: function ($$v) {
                  _vm.editorMode = $$v
                },
                expression: "editorMode",
              },
            },
            _vm._l(_vm.editorModes, function (editorMode) {
              return _c("option", {
                attrs: {
                  disabled:
                    editorMode.mode === "richText" &&
                    _vm.isEnabledRichText === false,
                },
                domProps: {
                  value: editorMode.mode,
                  textContent: _vm._s(_vm.$t(`term['${editorMode.mode}']`)),
                },
              })
            }),
            0
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        {
          attrs: {
            type: _vm.checkEmpty(_vm.errors.body) ? "" : "is-danger",
            message: _vm.checkEmpty(_vm.errors.body) ? "" : _vm.errors.body[0],
          },
        },
        [
          _vm.isEnabledRichText && _vm.editorMode === "richText"
            ? _c("rich-text-editor", {
                model: {
                  value: _vm.body,
                  callback: function ($$v) {
                    _vm.body = $$v
                  },
                  expression: "body",
                },
              })
            : _vm.editorMode === "markdown"
            ? _c("markdown-editor", {
                model: {
                  value: _vm.body,
                  callback: function ($$v) {
                    _vm.body = $$v
                  },
                  expression: "body",
                },
              })
            : _c("b-input", {
                ref: "inputBody",
                attrs: { type: "textarea" },
                on: {
                  blur: function ($event) {
                    return _vm.validate("body")
                  },
                },
                model: {
                  value: _vm.body,
                  callback: function ($$v) {
                    _vm.body = $$v
                  },
                  expression: "body",
                },
              }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        {
          staticClass: "mt-6",
          attrs: {
            label: _vm.$t("common.images"),
            type: _vm.checkEmpty(_vm.errors.images) ? "" : "is-danger",
            message: _vm.checkEmpty(_vm.errors.images)
              ? ""
              : _vm.$t("msg.ErrorsExist"),
          },
        },
        [
          _vm.uploaderOptions
            ? _c("file-uploader", {
                attrs: {
                  "file-type": "image",
                  "image-action-button-type":
                    _vm.editorMode === "markdown" ? "copy" : "insert",
                  "uploader-options": _vm.uploaderOptions.image,
                },
                on: { "insert-image": _vm.insertImage },
                model: {
                  value: _vm.images,
                  callback: function ($$v) {
                    _vm.images = $$v
                  },
                  expression: "images",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        {
          staticClass: "mt-6",
          attrs: {
            label: _vm.$t("form.files"),
            type: _vm.checkEmpty(_vm.errors.files) ? "" : "is-danger",
            message: _vm.checkEmpty(_vm.errors.files)
              ? ""
              : _vm.$t("msg.ErrorsExist"),
          },
        },
        [
          _vm.uploaderOptions
            ? _c("file-uploader", {
                attrs: {
                  "file-type": "file",
                  "uploader-options": _vm.uploaderOptions.file,
                },
                on: { "copy-url": _vm.copyUrl },
                model: {
                  value: _vm.files,
                  callback: function ($$v) {
                    _vm.files = $$v
                  },
                  expression: "files",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        {
          staticClass: "mt-6",
          attrs: {
            label: _vm.$t("common.link"),
            type: _vm.checkEmpty(_vm.errors.links) ? "" : "is-danger",
            message: _vm.checkEmpty(_vm.errors.links)
              ? ""
              : _vm.$t("msg.ErrorsExist"),
          },
        },
        [
          _vm.links.length > 0
            ? _c(
                "ul",
                _vm._l(_vm.links, function (link) {
                  return _c(
                    "li",
                    { key: link.id },
                    [
                      _c("link-inputs", {
                        attrs: { link: link },
                        on: {
                          "updated-link": _vm.updateLink,
                          delete: _vm.deleteLink,
                          "has-error": _vm.setLinksError,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button",
          attrs: { disabled: _vm.isAddLinkBtnEnabled === false },
          on: { click: _vm.addLink },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(_vm.$t("common.addFor", { target: _vm.$t("common.link") }))
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-field",
        {
          staticClass: "mt-6",
          attrs: {
            label: _vm.$t("common.tag"),
            type: _vm.checkEmpty(_vm.errors.tags) ? "" : "is-danger",
            message: _vm.checkEmpty(_vm.errors.tags)
              ? _vm.$t("form.ExpAboutNewTagsSeparater")
              : _vm.errors.tags[0],
          },
        },
        [
          _c("b-taginput", {
            attrs: {
              data: _vm.filteredTags,
              autocomplete: true,
              "allow-new": true,
              "open-on-focus": true,
              field: "label",
              "icon-pack": "fas",
              icon: "tag",
              placeholder: "",
            },
            on: { typing: _vm.getFilteredTags },
            model: {
              value: _vm.tags,
              callback: function ($$v) {
                _vm.tags = $$v
              },
              expression: "tags",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        {
          staticClass: "mt-6",
          attrs: {
            label: _vm.$t("common.publishAt"),
            type: _vm.checkEmpty(_vm.errors.publishAt) ? "" : "is-danger",
            message: _vm.checkEmpty(_vm.errors.publishAt)
              ? ""
              : _vm.errors.publishAt[0],
          },
        },
        [
          _c("b-datetimepicker", {
            attrs: {
              locale: "ja-JP",
              "icon-pack": "fas",
              icon: "calendar-day",
              editable: "",
            },
            on: {
              blur: function ($event) {
                return _vm.validate("publishAt")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "left",
                fn: function () {
                  return [
                    _c("b-button", {
                      attrs: {
                        label: "Now",
                        type: "is-primary",
                        "icon-left": "clock",
                        "icon-pack": "fas",
                      },
                      on: {
                        click: function ($event) {
                          _vm.publishAt = new Date()
                        },
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "right",
                fn: function () {
                  return [
                    _c("b-button", {
                      attrs: {
                        label: "Clear",
                        type: "is-danger",
                        "icon-left": "times",
                        "icon-pack": "fas",
                        outlined: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.publishAt = null
                        },
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.publishAt,
              callback: function ($$v) {
                _vm.publishAt = $$v
              },
              expression: "publishAt",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        {
          staticClass: "mt-6",
          attrs: {
            label: _vm.$t("common.dispSetting"),
            type: _vm.checkEmpty(_vm.errors.isHiddenInList) ? "" : "is-danger",
            message: _vm.checkEmpty(_vm.errors.isHiddenInList)
              ? ""
              : _vm.errors.isHiddenInList[0],
          },
        },
        [
          _c(
            "b-checkbox",
            {
              model: {
                value: _vm.isHiddenInList,
                callback: function ($$v) {
                  _vm.isHiddenInList = $$v
                },
                expression: "isHiddenInList",
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("form.hideInList")) + "\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.globalError
        ? _c("div", { staticClass: "block has-text-danger mt-6" }, [
            _vm._v(_vm._s(_vm.globalError)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "field mt-6" }, [
        !_vm.isPublished
          ? _c("div", { staticClass: "control" }, [
              _c("button", {
                staticClass: "button is-info",
                attrs: { disabled: _vm.isLoading || _vm.hasErrors },
                domProps: { textContent: _vm._s(_vm.$t("common.saveDraft")) },
                on: {
                  click: function ($event) {
                    return _vm.save(false)
                  },
                },
              }),
            ])
          : _c("div", { staticClass: "control" }, [
              _c("button", {
                staticClass: "button is-warning",
                attrs: { disabled: _vm.isLoading || _vm.hasErrors },
                domProps: { textContent: _vm._s(_vm.$t("common.edit")) },
                on: {
                  click: function ($event) {
                    return _vm.save(false)
                  },
                },
              }),
            ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field" }, [
        !_vm.isPublished
          ? _c("div", { staticClass: "control" }, [
              _c("button", {
                staticClass: "button is-warning",
                attrs: { disabled: _vm.isLoading || _vm.hasErrors },
                domProps: { textContent: _vm._s(_vm.$t("common.publish")) },
                on: {
                  click: function ($event) {
                    return _vm.save(true)
                  },
                },
              }),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "control" }, [
          _c("button", {
            staticClass: "button is-light",
            attrs: { disabled: _vm.isLoading },
            domProps: { textContent: _vm._s(_vm.$t("common.cancel")) },
            on: { click: _vm.cancel },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-link" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }