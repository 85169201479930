var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dropdown",
      class: [{ "is-active": _vm.isActive }, _vm.position],
    },
    [
      _c("div", { staticClass: "dropdown-trigger" }, [
        _c(
          "button",
          {
            staticClass: "button",
            class: _vm.btnClass,
            attrs: {
              "aria-haspopup": "true",
              "aria-controls": "dropdown-menu",
            },
            on: {
              click: function ($event) {
                _vm.isActive = !_vm.isActive
              },
            },
          },
          [
            _vm._t("label"),
            _vm._v(" "),
            !_vm.hiddenCaret
              ? _c("span", { staticClass: "icon is-small" }, [
                  _c("i", {
                    staticClass: "fas fa-angle-down",
                    attrs: { "aria-hidden": "true" },
                  }),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu",
          attrs: { id: "dropdown-menu", role: "menu" },
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }