<template>
  <div>
    <h1 class="title">{{ $t('page.about') }}</h1>
  </div>
</template>
<script>
export default{
  name: 'About',

  data(){
    return {
    }
  },
  created() {
  }
}
</script>
