var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm.isEmptyDatetime === false
      ? _c(
          "time",
          {
            class: _vm.timeClass,
            attrs: {
              itemprop: "time",
              datetime: _vm._f("dateFormat")(_vm.datetime, _vm.format),
            },
          },
          [_vm._v(_vm._s(_vm._f("dateFormat")(_vm.datetime)))]
        )
      : _c("span", [_vm._v("-")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }