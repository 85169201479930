var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { class: _vm.rowBgColoerClass }, [
    _c("td", [
      _vm.publishStatus == "published"
        ? _c("span", { staticClass: "tag is-success" }, [
            _vm._v(_vm._s(_vm.$t("common.published"))),
          ])
        : _vm.publishStatus == "reserved"
        ? _c("span", { staticClass: "tag is-warning" }, [
            _vm._v(_vm._s(_vm.$t("common.reserved"))),
          ])
        : _c("span", { staticClass: "tag is-danger" }, [
            _vm._v(_vm._s(_vm.$t("common.unpublished"))),
          ]),
      _vm._v(" "),
      _vm.post.isHiddenInList
        ? _c("span", { staticClass: "tag is-dark" }, [
            _vm._v(_vm._s(_vm.$t("common.hidden"))),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "td",
      [
        _c(
          "router-link",
          { attrs: { to: `/admin/posts/${_vm.serviceId}/${_vm.post.postId}` } },
          [_vm._v(_vm._s(_vm.post.title))]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("td", {
      staticClass: "is-size-7",
      domProps: {
        textContent: _vm._s(_vm.getCategoryLabel(_vm.post.categorySlug)),
      },
    }),
    _vm._v(" "),
    _c(
      "td",
      [
        _vm.hasEditorRole
          ? _c(
              "router-link",
              {
                staticClass: "button is-small",
                attrs: {
                  to: `/admin/posts/${_vm.serviceId}/${_vm.post.postId}/edit`,
                },
              },
              [
                _c("span", { staticClass: "icon is-small" }, [
                  _c("i", { staticClass: "fas fa-pen" }),
                ]),
              ]
            )
          : _c("span", [_vm._v("-")]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "is-size-7" },
      [_c("inline-time", { attrs: { datetime: _vm.post.publishAt } })],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "is-size-7" },
      [_c("inline-time", { attrs: { datetime: _vm.post.createdAt } })],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "is-size-7" },
      [_c("inline-time", { attrs: { datetime: _vm.post.updatedAt } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }