var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("h1", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("page.ShortenUrlList"))),
          ]),
          _vm._v(" "),
          _vm.hasEditorRole
            ? _c(
                "router-link",
                {
                  staticClass: "button",
                  attrs: { to: `/admin/shorten-urls/${_vm.serviceId}/create` },
                },
                [_vm._v(_vm._s(_vm.$t("common.createNew")))]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("admin-shorten-url-list", { staticClass: "mt-6" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }