var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.serviceId && (_vm.categories || _vm.tags)
      ? _c("aside", { staticClass: "menu" }, [
          _c("p", { staticClass: "menu-label" }, [
            _vm._v(_vm._s(_vm.$t("common.categories"))),
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "menu-list" },
            _vm._l(_vm.categories, function (category) {
              return _c("category-menu-item", {
                key: category.id,
                attrs: {
                  category: category,
                  "active-category-slug": _vm.currenCategorySlug,
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "menu-label" }, [
            _vm._v(_vm._s(_vm.$t("common.tags"))),
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "menu-list" },
            _vm._l(_vm.tags, function (tag) {
              return _c("tag-menu-item", {
                key: tag.tagId,
                attrs: { tag: tag, "active-label": _vm.currenTagLabel },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "menu-label" }, [
            _vm._v(_vm._s(_vm.$t("term.postGroup"))),
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "menu-list" },
            _vm._l(_vm.groups, function (group) {
              return _c(
                "li",
                { key: group.slug },
                [
                  _c(
                    "router-link",
                    {
                      class: {
                        "is-active": _vm.currenPostGroupSlug === group.slug,
                      },
                      attrs: {
                        to: `/posts/${_vm.serviceId}/groups/${group.slug}`,
                      },
                    },
                    [_vm._v(_vm._s(group.label))]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }