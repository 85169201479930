var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c("td", { staticClass: "is-size-6" }, [_vm._v(_vm._s(_vm.group.slug))]),
    _vm._v(" "),
    _c("td", { staticClass: "is-size-6" }, [_vm._v(_vm._s(_vm.group.label))]),
    _vm._v(" "),
    _c(
      "td",
      [
        _c(
          "router-link",
          {
            staticClass: "button is-small",
            attrs: { to: `/admin/posts/${_vm.serviceId}/groups/${_vm.slug}` },
          },
          [
            _c("span", { staticClass: "icon is-small" }, [
              _c("i", { staticClass: "fas fa-pen" }),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }