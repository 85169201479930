var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.comments.length > 0
      ? _c(
          "ul",
          _vm._l(_vm.comments, function (comment) {
            return _c(
              "li",
              { staticClass: "block" },
              [
                _c("comment-item", {
                  attrs: {
                    comment: comment,
                    "posted-votes": _vm.votes.filter(
                      (vote) => vote.contentId == comment.commentId
                    ),
                  },
                  on: { "post-vote": _vm.postVote },
                }),
              ],
              1
            )
          }),
          0
        )
      : !_vm.isLoading
      ? _c("div", [_c("p", [_vm._v(_vm._s(_vm.$t('msg["Data is empty"]')))])])
      : _vm._e(),
    _vm._v(" "),
    _vm.hasNext
      ? _c("nav", { staticClass: "u-mt2r" }, [
          _c(
            "a",
            {
              staticClass: "u-clickable",
              on: {
                click: function ($event) {
                  return _vm.fetchComments({ untilTime: _vm.lastItemCreatedAt })
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("common.more")))]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }