var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.$t("page.AdminUsers"))),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mt-5" }, [_c("admin-user-list")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }