var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-content" }, [
      _c("div", { staticClass: "media" }, [
        _c("div", { staticClass: "media-content" }, [
          _c(
            "h4",
            { staticClass: "title is-4" },
            [
              _c(
                "router-link",
                { attrs: { to: `/posts/${_vm.serviceId}/${_vm.post.slug}` } },
                [_vm._v(_vm._s(_vm.post.title))]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("div", { domProps: { textContent: _vm._s(_vm.post.bodyText) } }),
        _vm._v(" "),
        _c("div", { staticClass: "mt-5" }, [
          _c(
            "time",
            {
              attrs: {
                itemprop: "datepublished",
                datetime: _vm._f("dateFormat")(_vm.post.publishAt, ""),
              },
            },
            [_vm._v(_vm._s(_vm._f("dateFormat")(_vm.post.publishAt)))]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }