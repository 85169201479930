var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.post
    ? _c(
        "div",
        [
          _vm.post.postStatus !== "publish"
            ? _c("b-message", { attrs: { type: "is-danger" } }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("msg.thisIsUnpublished", {
                      name: _vm.$t("common.post"),
                    })
                  )
                ),
              ])
            : _vm.isPublished === false
            ? _c("b-message", { attrs: { type: "is-warning" } }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("msg.thisIsNotPublishedYet", {
                      name: _vm.$t("common.post"),
                    })
                  )
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.post.isHiddenInList === true
            ? _c("b-message", [_vm._v(_vm._s(_vm.$t("msg.hiddenInList")))])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("router-link", { attrs: { to: _vm.postsPageUriObj } }, [
                _c("i", { staticClass: "fas fa-chevron-left" }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.$t("common.posts")))]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("h1", { staticClass: "title" }, [
            _vm.isPublishItem === false
              ? _c("span", { staticClass: "tag is-danger" }, [
                  _vm._v(_vm._s(_vm.$t("common.unpublished"))),
                ])
              : _vm._e(),
            _vm._v("\n\n    " + _vm._s(_vm.post.title) + "\n  "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "is-clearfix" }, [
            _c(
              "div",
              { staticClass: "is-pulled-right" },
              [
                _c("eb-dropdown", { attrs: { position: "is-bottom-left" } }, [
                  _c(
                    "span",
                    {
                      staticClass: "icon",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [_c("i", { staticClass: "fas fa-edit" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dropdown-content" },
                    [
                      _vm.previewUrl
                        ? _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: _vm.previewUrl, target: "_blank" },
                            },
                            [
                              _c("span", { staticClass: "icon" }, [
                                _c("i", { staticClass: "fas fa-eye" }),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("common.preview"))),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasEditorRole
                        ? _c(
                            "router-link",
                            {
                              staticClass: "dropdown-item",
                              attrs: {
                                to: `/admin/posts/${_vm.serviceId}/${_vm.post.postId}/edit`,
                              },
                            },
                            [
                              _c("span", { staticClass: "icon" }, [
                                _c("i", { staticClass: "fas fa-pen" }),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("common.edit"))),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasEditorRole && _vm.isPublishItem
                        ? _c(
                            "a",
                            {
                              staticClass: "dropdown-item is-clickable",
                              on: {
                                click: function ($event) {
                                  return _vm.updateStatus(false)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "icon" }, [
                                _c("i", { staticClass: "fas fa-lock" }),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("common.unpublish"))),
                              ]),
                            ]
                          )
                        : _vm.hasEditorRole && !_vm.isPublishItem
                        ? _c(
                            "a",
                            {
                              staticClass: "dropdown-item is-clickable",
                              on: {
                                click: function ($event) {
                                  return _vm.confirmPublish()
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "icon" }, [
                                _c("i", { staticClass: "fas fa-globe" }),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("common.publish"))),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasEditorRole
                        ? _c(
                            "a",
                            {
                              staticClass: "dropdown-item is-clickable",
                              on: {
                                click: function ($event) {
                                  return _vm.confirmDelete()
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "icon" }, [
                                _c("i", { staticClass: "fas fa-trash" }),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("common.delete"))),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("post-body", { attrs: { body: _vm.post.bodyHtml } }),
          _vm._v(" "),
          _c("ul", { staticClass: "mt-5" }, [
            "images" in _vm.post && _vm.post.images.length > 0
              ? _c(
                  "li",
                  [
                    _c("span", [
                      _c(
                        "button",
                        {
                          staticClass: "button is-ghost",
                          on: {
                            click: function ($event) {
                              _vm.isImagesModalActive = true
                            },
                          },
                        },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.post.images.length))]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("b-modal", {
                      attrs: {
                        "destroy-on-hide": false,
                        "aria-role": "dialog",
                        "close-button-aria-label": "Close",
                        "aria-modal": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (props) {
                              return [
                                _c(
                                  "ul",
                                  _vm._l(_vm.post.images, function (image) {
                                    return _c("li", { staticClass: "mb-5" }, [
                                      _c("div", { staticClass: "card" }, [
                                        _c(
                                          "div",
                                          { staticClass: "card-image" },
                                          [
                                            _c("fb-img", {
                                              attrs: {
                                                fileId: image.fileId,
                                                mimeType: image.mimeType,
                                                size: "raw",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        image.caption
                                          ? _c(
                                              "div",
                                              { staticClass: "card-content" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "media" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "media-content",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(image.caption)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        932034751
                      ),
                      model: {
                        value: _vm.isImagesModalActive,
                        callback: function ($$v) {
                          _vm.isImagesModalActive = $$v
                        },
                        expression: "isImagesModalActive",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            "files" in _vm.post && _vm.post.files.length > 0
              ? _c("li", [
                  _c("label", [_vm._v(_vm._s(_vm.$t("common.files")))]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    _vm._l(_vm.post.files, function (file) {
                      return _c("li", [
                        _c("a", {
                          attrs: {
                            href: _vm.mediaUrl(
                              "file",
                              file.fileId,
                              file.mimeType
                            ),
                            target: "_blank",
                          },
                          domProps: {
                            textContent: _vm._s(
                              file.caption ? file.caption : file.fileId
                            ),
                          },
                        }),
                      ])
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            "links" in _vm.post && _vm.post.links.length > 0
              ? _c("li", [
                  _c("label", [_vm._v(_vm._s(_vm.$t("common.links")))]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    _vm._l(_vm.post.links, function (link) {
                      return _c("li", [
                        _c("a", {
                          attrs: { href: link.url, target: "_blank" },
                          domProps: {
                            textContent: _vm._s(
                              link.label ? link.label : link.url
                            ),
                          },
                        }),
                      ])
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            "category" in _vm.post && _vm.post.category
              ? _c("li", [
                  _c("label", [_vm._v(_vm._s(_vm.$t("common.category")))]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.post.category.label))]),
                ])
              : _vm._e(),
            _vm._v(" "),
            "tags" in _vm.post && _vm.post.tags
              ? _c("li", [
                  _c("label", [_vm._v(_vm._s(_vm.$t("common.tag")))]),
                  _vm._v(" "),
                  _c(
                    "span",
                    _vm._l(_vm.post.tags, function (tag) {
                      return _c("span", { staticClass: "tag ml-2" }, [
                        _vm._v(_vm._s(tag.label)),
                      ])
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "li",
              [
                _c("label", [_vm._v(_vm._s(_vm.$t("common.publishAt")))]),
                _vm._v(" "),
                _c("inline-time", {
                  attrs: {
                    "time-class": _vm.isReserved ? "has-text-warning-dark" : "",
                    datetime: _vm.post.publishAt,
                  },
                }),
                _vm._v(" "),
                _vm.isReserved
                  ? _c("span", { staticClass: "tag is-warning" }, [
                      _vm._v(_vm._s(_vm.$t("common.reserved"))),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c("label", [_vm._v(_vm._s(_vm.$t("common.lastUpdatedAt")))]),
                _vm._v(" "),
                _c("inline-time", { attrs: { datetime: _vm.post.updatedAt } }),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-images" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }