var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tabs" }, [
    _c("ul", [
      _c(
        "li",
        {
          class: {
            "is-active": this.$route.path === `/admin/posts/${_vm.serviceId}`,
          },
        },
        [
          _c(
            "router-link",
            { attrs: { to: `/admin/posts/${_vm.serviceId}` } },
            [_vm._v(_vm._s(_vm.$t("common.posts")))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          class: {
            "is-active":
              this.$route.path === `/admin/posts/${_vm.serviceId}/groups`,
          },
        },
        [
          _c(
            "router-link",
            { attrs: { to: `/admin/posts/${_vm.serviceId}/groups` } },
            [_vm._v(_vm._s(_vm.$t("page.AdminPostGroupManagement")))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }