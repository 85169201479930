var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.$t("page.adminTop"))),
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(_vm._s(_vm.$t("msg.signInGreeting", { name: _vm.adminUserName }))),
    ]),
    _vm._v(" "),
    _vm.services
      ? _c(
          "div",
          { staticClass: "mt-6" },
          _vm._l(_vm.services, function (service) {
            return _c("div", { key: service.serviceId, staticClass: "box" }, [
              _c("h3", { staticClass: "title is-4" }, [
                _c("span", [_vm._v(_vm._s(service.label))]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "ml-1 has-text-weight-normal is-size-6" },
                  [_vm._v("(" + _vm._s(service.serviceId) + ")")]
                ),
              ]),
              _vm._v(" "),
              service.functions
                ? _c("div", { staticClass: "block" }, [
                    _c(
                      "ul",
                      _vm._l(service.functions, function (functionKey) {
                        return _c(
                          "li",
                          { staticClass: "is-size-5 mt-2" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: _vm.getFunctionUrl(
                                    service.serviceId,
                                    functionKey
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        `page.adminFunctions["${functionKey}"]`
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ])
                : _c("div", [_vm._v(_vm._s(_vm.$t('msg["No Data"]')))]),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }