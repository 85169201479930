var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shortenUrl
    ? _c("div", [
        _c(
          "div",
          { staticClass: "block" },
          [
            _c("router-link", { attrs: { to: _vm.listPageUri } }, [
              _c("i", { staticClass: "fas fa-chevron-left" }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("page.ShortenUrlList")))]),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("h1", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.shortenUrl.urlId)),
        ]),
        _vm._v(" "),
        _c("h2", { staticClass: "subtitle" }, [
          _vm._v(_vm._s(_vm.shortenUrl.name)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "is-pulled-right" },
          [
            _vm.hasEditorRole
              ? _c("eb-dropdown", { attrs: { position: "is-bottom-left" } }, [
                  _c(
                    "span",
                    {
                      staticClass: "icon",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [_c("i", { staticClass: "fas fa-edit" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dropdown-content" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: {
                            to: `/admin/shorten-urls/${_vm.serviceId}/${_vm.shortenUrl.urlId}/edit`,
                          },
                        },
                        [
                          _c("span", { staticClass: "icon" }, [
                            _c("i", { staticClass: "fas fa-pen" }),
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item is-clickable",
                          on: {
                            click: function ($event) {
                              return _vm.confirmDelete()
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "icon" }, [
                            _c("i", { staticClass: "fas fa-trash" }),
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.$t("common.delete")))]),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _vm._v(_vm._s(_vm.shortenUrl.description)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-6" }, [
          _c("div", { staticClass: "field" }, [
            _c("label", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("common.locationTo"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "control" }, [
              _c(
                "a",
                { attrs: { href: _vm.shortenUrl.url, target: "_blank" } },
                [_vm._v(_vm._s(_vm.shortenUrl.url))]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field mt-5" }, [
            _c("label", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("form.isViaJumpPageLabel"))),
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "control",
              domProps: {
                textContent: _vm._s(
                  _vm.shortenUrl.isViaJumpPage
                    ? _vm.$t("form.viaJumpPage")
                    : _vm.$t("form.notViaJumpPage")
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _c("label", { staticClass: "label" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("common.paramsFor", {
                    target: _vm.$t("term.accessAnalysis"),
                  })
                )
              ),
            ]),
            _vm._v(" "),
            _vm.shortenUrl.paramKey
              ? _c("div", {
                  staticClass: "control",
                  domProps: {
                    textContent: _vm._s(
                      `${_vm.shortenUrl.paramKey}=${_vm.shortenUrl.paramValue}`
                    ),
                  },
                })
              : _c("div", { staticClass: "control" }, [
                  _vm._v(_vm._s(_vm.$t("msg.Unregistered"))),
                ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field mt-5" }, [
            _c("label", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("term.generatedUrl"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "control is-size-5 u-wrap" }, [
              _c(
                "a",
                {
                  attrs: { href: _vm.shortenUrl.locationTo, target: "_blank" },
                },
                [_vm._v(_vm._s(_vm.shortenUrl.locationTo))]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-6 p-4 has-background-light" }, [
          _c("h3", { staticClass: "title is-4" }, [
            _vm._v(_vm._s(_vm.$t("term.shortenUrl"))),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "a",
              {
                staticClass: "is-size-5",
                attrs: { href: _vm.redirectUrl, target: "_blank" },
              },
              [_vm._v(_vm._s(_vm.redirectUrl))]
            ),
          ]),
          _vm._v(" "),
          _vm.isDispQrCode
            ? _c("div", { staticClass: "mt-3" }, [
                _c("div", [_c("img", { attrs: { src: _vm.qrCodeUrl } })]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-2" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.qrCodeUrl,
                        download: `${_vm.urlId}.png`,
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("common.download")))]
                  ),
                ]),
              ])
            : _vm.isLoading === false
            ? _c("div", { staticClass: "mt-3 has-text-warning-dark" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("msg.generateItemRequiresTimes", {
                      target: _vm.$t("common.images"),
                    })
                  )
                ),
              ])
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }