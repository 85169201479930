var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "signIn" },
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("page.adminSignIn"))),
      ]),
      _vm._v(" "),
      _c("eb-sign-in-form", { attrs: { "is-use-admin": true } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }