var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-image-box" },
    [
      _vm.isFileObject
        ? _c("div", { staticClass: "image" }, [
            "thumb" in _vm.file && _vm.file.thumb
              ? _c("img", { attrs: { src: _vm.file.thumb } })
              : _c("span", [_vm._v("No Image")]),
          ])
        : _c(
            "div",
            { staticClass: "image" },
            [
              _c("fb-img", {
                attrs: {
                  fileId: _vm.file.fileId,
                  mimeType: _vm.file.mimeType,
                  size: "raw",
                },
              }),
            ],
            1
          ),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "has-text-danger" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : _vm.file.isUploaded
        ? _c("div", { staticClass: "has-text-success" }, [_vm._v("Uploaded")])
        : _vm._e(),
      _vm._v(" "),
      _vm.enableCaption && _vm.isFileObject === false
        ? _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: _vm.$t("common.caption"),
                    "label-position": "inside",
                  },
                },
                [
                  _c("b-input", {
                    on: { blur: _vm.inputCaption },
                    model: {
                      value: _vm.caption,
                      callback: function ($$v) {
                        _vm.caption = $$v
                      },
                      expression: "caption",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isFileObject === false
        ? _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c(
                "b-select",
                {
                  model: {
                    value: _vm.insertSize,
                    callback: function ($$v) {
                      _vm.insertSize = $$v
                    },
                    expression: "insertSize",
                  },
                },
                [
                  _c("option", { attrs: { value: "raw" } }, [
                    _vm._v(_vm._s(_vm.$t("common.originalSize"))),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.sizes, function (size) {
                    return _c("option", { domProps: { value: size } }, [
                      _vm._v(_vm._s(size)),
                    ])
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mt-2" }, [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    on: {
                      click: function ($event) {
                        return _vm.insertImage()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "icon" }, [
                      _vm.actionButtonType === "copy"
                        ? _c("i", { staticClass: "fas fa-copy" })
                        : _c("i", { staticClass: "fas fa-plus" }),
                    ]),
                    _vm._v(" "),
                    _vm.actionButtonType === "copy"
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("common.copy")))])
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("common.insertOf", {
                                name: _vm.$t("common.image"),
                              })
                            )
                          ),
                        ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.file.isUploaded
                ? _c("div", { staticClass: "mt-2 has-text-warning-dark" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.$t("msg.generateItemRequiresTimes", {
                            target: _vm.$t("common.thumbnails"),
                          })
                        ) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-light is-small btn-delete",
          on: { click: _vm.deleteFile },
        },
        [_vm._m(0)]
      ),
      _vm._v(" "),
      _c("b-loading", {
        attrs: { "is-full-page": false },
        model: {
          value: _vm.isUploading,
          callback: function ($$v) {
            _vm.isUploading = $$v
          },
          expression: "isUploading",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-times-circle" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }