export const SET_COMMON_LOADING = 'SET_COMMON_LOADING'
export const SET_COMMON_HEADER_MENU_OPEN = 'SET_COMMON_HEADER_MENU_OPEN'
export const SET_ADMIN_USER = 'SET_ADMIN_USER'
export const SET_CATEGORY_ITEMS = 'SET_CATEGORY_ITEMS'
export const PUSH_ITEM_TO_ADMIN_POSTS_PAGER_KEYS = 'PUSH_ITEM_TO_ADMIN_POSTS_PAGER_KEYS'
export const RESET_ADMIN_POSTS_PAGER = 'RESET_ADMIN_POSTS_PAGER'
export const SET_ADMIN_POSTS_PAGER_LAST_INDEX = 'SET_ADMIN_POSTS_PAGER_LAST_INDEX'
export const SET_ADMIN_POSTS_PAGER_PARAMS = 'SET_ADMIN_POSTS_PAGER_PARAMS'
export const PUSH_ITEM_TO_ADMIN_SHORTEN_URLS_PAGER_KEYS = 'PUSH_ITEM_TO_ADMIN_SHORTEN_URLS_PAGER_KEYS'
export const RESET_ADMIN_SHORTEN_URLS_PAGER = 'RESET_ADMIN_SHORTEN_URLS_PAGER'
export const SET_ADMIN_SHORTEN_URLS_PAGER_LAST_INDEX = 'SET_ADMIN_SHORTEN_URLS_PAGER_LAST_INDEX'
//export const AUTH_SET_USER = 'AUTH_SET_USER'
//export const AUTH_UPDATE_USER_INFO = 'AUTH_UPDATE_USER_INFO'
//export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN'
//export const AUTH_UPDATE_STATE = 'AUTH_UPDATE_STATE'

