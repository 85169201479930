var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isEdit === false
        ? _c(
            "b-field",
            {
              attrs: {
                label: _vm.$t("form.Slug"),
                type: _vm.checkEmpty(_vm.errors.slug) ? "" : "is-danger",
                message: _vm.checkEmpty(_vm.errors.slug)
                  ? ""
                  : _vm.errors.slug[0],
              },
            },
            [
              _c("b-input", {
                on: {
                  blur: function ($event) {
                    return _vm.validate("slug")
                  },
                },
                model: {
                  value: _vm.slug,
                  callback: function ($$v) {
                    _vm.slug = $$v
                  },
                  expression: "slug",
                },
              }),
            ],
            1
          )
        : _c("b-field", { attrs: { label: _vm.$t("form.Slug") } }, [
            _vm._v(_vm._s(this.slug)),
          ]),
      _vm._v(" "),
      _c(
        "b-field",
        {
          staticClass: "mt-5",
          attrs: {
            label: _vm.$t("common.dispLabel"),
            type: _vm.checkEmpty(_vm.errors.label) ? "" : "is-danger",
            message: _vm.checkEmpty(_vm.errors.label)
              ? ""
              : _vm.errors.label[0],
          },
        },
        [
          _c("b-input", {
            on: {
              blur: function ($event) {
                return _vm.validate("label")
              },
            },
            model: {
              value: _vm.label,
              callback: function ($$v) {
                _vm.label = $$v
              },
              expression: "label",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.globalError
        ? _c("div", { staticClass: "block has-text-danger mt-5" }, [
            _vm._v(_vm._s(_vm.globalError)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "field mt-5" }, [
        _c("div", { staticClass: "control" }, [
          _c("button", {
            staticClass: "button is-info",
            attrs: { disabled: _vm.isLoading || _vm.hasErrors },
            domProps: {
              textContent: _vm._s(
                _vm.isEdit ? _vm.$t("common.edit") : _vm.$t("common.create")
              ),
            },
            on: {
              click: function ($event) {
                return _vm.save()
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "control" }, [
          _c("button", {
            staticClass: "button is-light",
            attrs: { disabled: _vm.isLoading },
            domProps: { textContent: _vm._s(_vm.$t("common.cancel")) },
            on: { click: _vm.cancel },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }