var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _c(
      "a",
      {
        on: {
          click: function ($event) {
            return _vm.vote(_vm.voteType)
          },
        },
      },
      [
        _c("span", { staticClass: "icon is-small" }, [
          _c("i", { class: _vm.icon }),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("span", { staticClass: "ml-1" }, [
      _vm._v(_vm._s(_vm._f("numFormat")(_vm.voteCount))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }