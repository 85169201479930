var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c(
      "td",
      [
        _c(
          "router-link",
          {
            attrs: {
              to: `/admin/shorten-urls/${_vm.serviceId}/${_vm.shortenUrl.urlId}`,
            },
          },
          [_vm._v(_vm._s(_vm.shortenUrl.urlId))]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.shortenUrl.name))]),
    _vm._v(" "),
    _c("td", { staticClass: "is-size-7" }, [
      _vm._v(_vm._s(_vm.shortenUrl.url)),
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "is-size-6 has-text-success" }, [
      _vm.shortenUrl.isViaJumpPage
        ? _c("i", { staticClass: "fas fa-check" })
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "td",
      [
        _vm.hasEditorRole
          ? _c(
              "router-link",
              {
                staticClass: "button is-small",
                attrs: {
                  to: `/admin/shorten-urls/${_vm.serviceId}/${_vm.shortenUrl.urlId}/edit`,
                },
              },
              [
                _c("span", { staticClass: "icon is-small" }, [
                  _c("i", { staticClass: "fas fa-pen" }),
                ]),
              ]
            )
          : _c("span", [_vm._v("-")]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "is-size-7" },
      [_c("inline-time", { attrs: { datetime: _vm.shortenUrl.createdAt } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }